import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RequestQuote from "../components/RequestQuote";
import Quoteimg from "../assets/img/qouteelementor.png";
import inqD from "../assets/img/caseStudy/inqD.jpg";

const DigitalClientCaseStudy = () => {
  return (
    <div>
      <Navbar />

      <div className="case_title_container_one">
        <main>
        <div className="bpo-intr-containr">
          <div className="bpo-intr-contnt">
            <h4>
            How Outcess Empowered a Financial Service Company to Overcome Operational Challenges and Scale
            </h4>
          </div>
          </div>
        </main>
      </div>
     
      <section className="custodian-hero-section">
          <main>
            <p className="custodian-subheading">
              Outcess helped the client enhance their contact center
              operations, overcome inefficiencies, and drive scalability,
              enabling them to handle increased demand and boost customer
              satisfaction.
            </p>
            <img src={inqD} alt="Quote img" className="Quoteimg" />
          </main>
        </section>

        {/* Background Section */}
        <section className="custodian-background-section">
          <main>
            <h3 className="custodian-subsection-title">Background</h3>
            <p className="custodian-text">
            The client, a global leader in digital transformation, prides itself on delivering cutting-edge solutions that empower businesses worldwide, the customer needed a partner who could recruit skilled personnel, provide proactive leadership, and ensure operational efficiency to meet their growing demands.
            </p>
            <p className="custodian-text">
              Recognizing the need for better systems and processes, the client
              turned to Outcess to help them overcome these operational
              challenges and establish a high-performing, scalable contact
              center that would meet growing customer demands.
            </p>
          </main>
        </section>

     
        <section className="custodian-challenge-section">
           <main>
            <h2 className="custodian-section-title">The Challenge</h2>
            <p className="custodian-text">The client faced operational challenges including high employee turnover, inconsistent service quality, and limited quality assurance processes. These issues disrupted service continuity, hindered customer satisfaction, and created inefficiencies as the company scaled its contact center operations. A comprehensive solution was needed to stabilise the workforce, improve service consistency, and ensure proactive management and quality assurance.</p>
          </main> 
        </section>

        {/* Our Approach Section */}
        <section className="custodian-approach-section">
          <main>
            <h2 className="custodian-section-title">Our Approach</h2>
            <p className="custodian-text">
            Outcess implemented a tailored strategy to transform the client’s contact center operations, focusing on addressing their specific challenges in people, processes, and technology.
            </p>
            <div className="custodian-approach-container">
              <div className="custodian-approach-item">
                <h3 className="custodian-approach-title">People</h3>
                <p className="custodian-approach-text">
                Our skilled team underwent rigorous training to align with the client's service standards, creating a customer-centric culture across the contact center. Proactive leadership provided by the recruited team leads and managers further drove continuous improvement and kept operations aligned with company goals.
                </p>
              </div>
              <div className="custodian-approach-item">
                <h3 className="custodian-approach-title">Processes</h3>
                <p className="custodian-approach-text mb-3">
                To standardise and enhance service quality, Outcess established robust workflows that streamlined daily operations. Our approach included:
                </p>
                <ul className="custodian-challenges-list"></ul>
                <li className="mb-3">
                <span className="custodian-challenge-text">
                 Quality Assurance Integration: Deploying QA personnel to monitor customer interactions and provide actionable feedback, ensuring consistent service delivery.
                </span>
                </li>
                <li className="mb-3">
                <span className="custodian-challenge-text">
                 Proactive Management Practices: Implementing processes that enabled team leads and managers to address issues swiftly, maintain performance standards, and reduce service disruptions.
                </span>
                </li>
                <li>
                <span className="custodian-challenge-text">
                 Turnover Mitigation: Developing strategies to improve employee satisfaction and retention, resulting in a more stable and experienced team.
                </span>
                </li>
              </div>
              <div className="custodian-approach-item">
                <h3 className="custodian-approach-title">Technology</h3>
                <p className="custodian-approach-text mb-3">
                Outcess leveraged technology to drive efficiency and performance monitoring within the client's contact center. Key initiatives included:
                </p>
                <ul className="custodian-challenges-list mb-3"></ul>
                <li  className="mb-3">
                <span className="custodian-approach-text">
                  Advanced Reporting Tools: Introducing analytics to track agent performance, customer satisfaction, and service quality in real time.
                </span>
                </li>
                <li>
                <span className="custodian-challenge-text">
                 Performance Tracking Systems: Implementing tools to evaluate team productivity and identify areas for improvement.
                </span>
                </li>
              </div>
            </div>
          </main>
        </section>

        {/* Outcomes Section */}
        <section className="custodian-outcomes-section">
          <main>
            <h2 className="custodian-section-title">Outcomes</h2>
            <div className="custodian-approach-title">
            <h1>Outcess’s tailored approach yielded significant results for the client:</h1>
            </div>
            <div className="custodian-outcome-item">
              <p className="custodian-outcome-title">Reduced Turnover</p>
              <p className="custodian-outcome-text">
              Recruitment and proactive management stabilized the workforce, ensuring a consistent team of skilled personnel.
              </p>
            </div>
            <div className="custodian-outcome-item">
              <p className="custodian-outcome-title">
                Improved Service Quality
              </p>
              <p className="custodian-outcome-text">
               QA efforts and leadership enhancements resulted in higher-quality customer interactions and improved satisfaction scores..
              </p>
            </div>
            <div className="custodian-outcome-item">
              <p className="custodian-outcome-title">Stronger Leadership</p>
              <p className="custodian-outcome-text">
               Stronger Leadership: Experienced managers and team leads provided the guidance needed to maintain high service standards and drive performance.
              </p>
            </div>
            <div className="custodian-outcome-item">
              <p className="custodian-outcome-title">Optimized Performance Monitoring</p>
              <p className="custodian-outcome-text">
               Enhanced analytics tools enabled the client to track and maintain operational excellence.
              </p>
            </div>
          </main>
        </section>
    
    
    

      <section>
        <main>
          <div className="request_quote_container">
            <img src={Quoteimg} alt="Quote img" className="Quoteimg" />

            <div>
              <RequestQuote />
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default DigitalClientCaseStudy;
