import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RequestQuote from "../components/RequestQuote";
import Quoteimg from "../assets/img/qouteelementor.png";

const LeadingEntertainmentClientCaseStudy = () => {
  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <div className="case_title_container4">
        <main>
          <div className="case_title">
            <h1>
            Driving Subscription Loyalty: How Outcess Helped a leading entertainment provider Enhance Customer Engagement and Revenue
            </h1>
          </div>
        </main>
      </div>
      {/* Background Section */}

      {/* Background Section */}
      <section className="multiChoice-background-section">
        <main>
          <h3 className="multiChoice-subsection-title">Background</h3>
          <p className="multiChoice-text">
            A leading entertainment provider, faced growing
            challenges in managing its subscriber base amidst increasing
            competition and customer expectations. The issues included high
            subscription lapse rates, limited direct engagement, and
            difficulties communicating with a multilingual, diverse audience
            across regions. With recurring revenue at risk, the client sought a
            reliable partner to optimize outbound communication, reduce churn,
            and maintain high-quality customer engagement.
          </p>
        </main>
      </section>

      {/* The Challenge Section */}
      <section className="multiChoice-challenge-section">
        <main>
          <h2 className="multiChoice-section-title">The Challenge</h2>
          <p className="multiChoice-text">
            The client’s challenges were rooted in people, processes, and
            technology:
          </p>
          <ul className="multiChoice-challenges-list">
            <li>
              <span className="multiChoice-challenge-icon">👥</span>
              <span className="multiChoice-challenge-text">
                People: Limited in-house capacity to manage high outbound
                communication volumes and provide multilingual support for
                customers in other languages.
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-icon">⚙️</span>
              <span className="multiChoice-challenge-text">
                Processes: Inefficient subscription renewal workflows resulted
                in lapses, service disruptions, and reduced customer
                satisfaction.
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-icon">📊</span>
              <span className="multiChoice-challenge-text">
                Technology: Lack of real-time insights and automation hindered
                proactive engagement and scalable operational efficiency.
              </span>
            </li>
          </ul>
        </main>
      </section>

      {/* Our Approach Section */}
      <section className="multiChoice-approach-section">
        <main>
          <h2 className="multiChoice-section-title">Our Approach</h2>
          <p className="multiChoice-text">
            To address the client's challenges, Outcess implemented a
            comprehensive strategy grounded in people, processes, and technology
            to drive results and ensure customer satisfaction.
          </p>
          <div className="multiChoice-approach-container">
            <div className="multiChoice-approach-item">
              <h3 className="multiChoice-approach-title">People</h3>
              <p className="multiChoice-approach-text">
              We began by focusing on people, deploying a team of highly trained agents proficient in customer engagement. These agents were not only skilled in proactive communication but also equipped to handle the diverse needs of the client's multilingual audience. By providing seamless support in their customer’s preferred language English, we ensured that every customer interaction was effective and personalised. Agents leveraged subscription data to craft tailored conversations that emphasized the benefits of uninterrupted programming, fostering stronger connections with customers.
              </p>
            </div>
            <div className="multiChoice-approach-item">
              <h3 className="multiChoice-approach-title">Processes</h3>
              <p className="multiChoice-approach-text">
              Our next focus was on processes. We redesigned subscription renewal workflows to make them more efficient and customer friendly. This included proactive engagement with customers nearing subscription expiration, minimising service lapses and ensuring a smooth renewal experience.
              By embedding feedback collection into every interaction, we created a system that continually refined its approach to meet evolving customer needs. Additionally, we prioritised reducing churn by emphasising value in our outreach efforts, encouraging customers to remain loyal to the client's services.
              </p>
            </div>
            <div className="multiChoice-approach-item">
              <h3 className="multiChoice-approach-title">Technology</h3>
              <p className="multiChoice-approach-text">
              Lastly, we integrated technology to enhance operational efficiency and scalability. Advanced analytics tools provided real-time insights, allowing us to monitor and adjust engagement strategies dynamically. Automation played a key role in handling repetitive tasks such as initial reminders, freeing agents to focus on high-value interactions. Our flexible engagement model ensured consistent service quality, even as call volumes fluctuated, enabling us to deliver measurable improvements across key performance metrics.
              </p>
              <p className="multiChoice-approach-text">
                Lastly, we integrated technology to enhance operational
                efficiency and scalability. Advanced analytics tools provided
                real-time insights, allowing us to monitor and adjust engagement
                strategies dynamically. Automation played a key role in handling
                repetitive tasks such as initial reminders, freeing agents to
                focus on high-value interactions. Our flexible engagement model
                ensured consistent service quality, even as call volumes
                fluctuated, enabling us to deliver measurable improvements
                across key performance metrics.
              </p>
            </div>
          </div>
        </main>
      </section>

      {/* Outcomes Section */}
      <section className="multiChoice-outcomes-section">
        <main>
          <h2 className="multiChoice-section-title">Outcomes</h2>
          <h3>The partnership between the client and Outcess delivered remarkable results:</h3>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Increased Renewals</p>
            <p className="multiChoice-outcome-text">
              Achieved a 25% improvement in subscription renewals within the
              first quarter.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Customer Satisfaction</p>
            <p className="multiChoice-outcome-text">
              Enhanced the customer experience through personalized support and
              proactive outreach.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Revenue Growth</p>
            <p className="multiChoice-outcome-text">
              Boosted recurring revenue by reducing subscription lapses and
              minimizing churn.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Operational Efficiency</p>
            <p className="multiChoice-outcome-text">
              Managed high call volumes with consistent quality, freeing
              internal resources for strategic priorities.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Actionable Insights</p>
            <p className="multiChoice-outcome-text">
              Delivered data-driven reports that informed future engagement
              strategies and optimized campaign performance.
            </p>
          </div>
        </main>
      </section>
      <section>
        <main>
          <div className="request_quote_container">
            <img src={Quoteimg} alt="Quote img" className="Quoteimg" />

            <div>
              <RequestQuote />
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default LeadingEntertainmentClientCaseStudy;
