import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RequestQuote from "../components/RequestQuote";
import Quoteimg from "../assets/img/qouteelementor.png";

const RevolutionizingDebtRecoveryCaseStudy = () => {
  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <div className="case_title_container8">
        <main>
          <div className="case_title">
            <h1>
            Revolutionizing Debt Recovery for a Leading Financial Institution
            </h1>
          </div>
        </main>
      </div>
      {/* Background Section */}

      {/* Background Section */}
      <section className="multiChoice-background-section">
        <main>
          <h3 className="multiChoice-subsection-title">Background</h3>
          <p className="multiChoice-text">
            One of Nigeria's foremost financial institutions, partnered with Outcess to enhance its debt recovery processes. Their goal was leverage Outcess’s expertise in customer engagement and debt collection to deliver impactful results while preserving positive customer relationships.
          </p>
        </main>
      </section>

      {/* The Challenge Section */}
      <section className="multiChoice-challenge-section">
        <main>
          <h2 className="multiChoice-section-title">The Challenge</h2>
          <p className="multiChoice-text">
           The customer faced increasing challenges in recovering overdue debts, which adversely affected its financial performance and operational efficiency. Inefficient communication with defaulting customers, coupled with the need to maintain brand reputation, created significant obstacles. The bank sought a reliable partner to streamline debt recovery, improve collections, and provide actionable insights to refine recovery strategies.
          </p>
          <ul className="multiChoice-challenges-list">
            <li>
              <span className="multiChoice-challenge-icon">👥</span>
              <span className="multiChoice-challenge-text">
                People: From a people perspective, they struggled to ensure that their support agents were consistently trained to handle complex inquiries effectively while maintaining high service standards. Flexibility and resilience were also significant concerns, as their team lacked the capacity to adapt quickly to fluctuating demands, which often led to delayed responses and diminished service quality. Furthermore, there was a pressing need to align agent performance with clear targets for key metrics, including first response times and customer satisfaction scores (CSAT).
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-icon">⚙️</span>
              <span className="multiChoice-challenge-text">
                Processes: On the process front, the rapid growth of their user base and geographic expansion posed challenges in managing increasing volumes of customer enquiries. Ensuring consistent service quality across diverse channels, such as email, chat, social media, and voice, proved difficult. Additionally, the absence of defined escalation pathways led to unresolved issues, resulting in customer dissatisfaction.
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-icon">📊</span>
              <span className="multiChoice-challenge-text">
                Technology: Technologically, their existing systems was inadequate for managing inquiries efficiently across multiple communication channels. Limited analytics capabilities hindered their ability to collect, analyse, and forecast data, which was critical for informed decision-making. Finally, balancing technological investments with the need for operational cost efficiency remained an ongoing challenge.
              </span>
            </li>
          </ul>
        </main>
      </section>

      {/* Our Approach Section */}
      <section className="multiChoice-approach-section">
        <main>
          <h2 className="multiChoice-section-title">Our Approach</h2>
          <p className="multiChoice-text">
            Outcess implemented a comprehensive debt recovery solution tailored to meet the client's specific challenges. The approach integrated expertise, innovative processes, and advanced technology to maximize effectiveness:
          </p>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Skilled Recovery Agents:</p>
            <p className="multiChoice-outcome-text">
              Outcess recruited and trained a dedicated team of agents specializing in debt recovery and customer relationship management. Our agents were equipped with negotiation skills, empathy, and conflict resolution techniques to engage customers effectively.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Streamlined Communication:</p>
            <p className="multiChoice-outcome-text">
              A multi-channel communication strategy, encompassing voice calls, SMS, and emails, ensured consistent and effective reminders for overdue payments. This approach also offered flexible repayment options, fostering collaboration rather than conflict.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Data-Driven Strategies:</p>
            <p className="multiChoice-outcome-text">
              By implementing advanced analytics and reporting tools, we tracked recovery performance and segmented customers based on repayment likelihood. These insights allowed for targeted engagement, increasing recovery efficiency.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Compliance and Professionalism:</p>
            <p className="multiChoice-outcome-text">
              We adhered strictly to regulatory guidelines and the client's internal policies, ensuring ethical practices and maintaining the bank's brand reputation throughout the debt recovery process.
            </p>
          </div>
        </main>
      </section>

      {/* Outcomes Section */}
      <section className="multiChoice-outcomes-section">
        <main>
          <h2 className="multiChoice-section-title">Results</h2>
          <h3>The collaboration yielded significant outcomes within the first year:</h3>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Improved Recovery Rates:</p>
            <p className="multiChoice-outcome-text">
              A 20% increase in overdue debt recovery substantially reduced the client's non-performing loan ratio.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Enhanced Customer Relationships:</p>
            <p className="multiChoice-outcome-text">
              Empathetic communication strategies preserved trust, leading to a 25% rise in customers resuming regular banking activities.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Operational Cost Reduction:</p>
            <p className="multiChoice-outcome-text">
              By outsourcing debt recovery, the client achieved a 30% reduction in operational costs, boosting overall efficiency.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Actionable Insights:</p>
            <p className="multiChoice-outcome-text">
               Detailed weekly and monthly reports enabled the client to make informed decisions and continuously improve its debt recovery strategies.
            </p>
          </div>
          {/* <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Predictive Insights:</p>
            <p className="multiChoice-outcome-text">
               Enabled more effective capacity planning and campaign management through analytics-driven forecasting.
            </p>
          </div>
          <p className="mb-3">These results not only addressed the immediate operational challenges but also set the foundation for scalable growth, ensuring the client’s customer support systems could evolve alongside their business needs.</p>
          <p>In conclusion, Outcess understands the unique challenges of the fintech industry and the importance of delivering exceptional customer experiences. By combining expertise in people management, process optimisation, and technological innovation, we provided a solution that not only met but exceeded the client’s expectations. Our approach created a robust framework for future growth, enabling the client to focus on their core business and market expansion with confidence. Let us partner with you to navigate the complexities of scaling customer operations and achieve long-term success.</p> */}
        </main>
      </section>
      <section>
        <main>
          <div className="request_quote_container">
            <img src={Quoteimg} alt="Quote img" className="Quoteimg" />

            <div>
              <RequestQuote />
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default RevolutionizingDebtRecoveryCaseStudy;
