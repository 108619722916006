import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faTasks,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import RFQModal from "../components/RFQModal";

const BusinessProdSolution = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <div id="business-productivity-solutions">
      <Navbar />

      <div className="bps-intro">
        <div className="bps-intr-containr">
          <div className="bps-intr-contnt">
            <h1>
              Business <span>Productivity</span> Solutions
            </h1>
            <h4>
              To boost business organisations’ productivity, Outcess takes an
              enterprise- wide approach to build an empowered and agile
              workplace that maximizes employee output and satisfaction, and
              delivers sustainable value to customers.
            </h4>
            <button className="ccsnl-btn" onClick={handleShow}>
              Book a Demo
            </button>
          </div>
        </div>
      </div>
      <RFQModal show={show} setShow={setShow} />
      <main>
        <section className="biz-sol-dsgn">
          <div className="biz-sol-dsgn-info" data-aos="fade-right">
            <h4>
              Our Business Productivity solutions are designed with the
              organisations strategic priorities in mind, while ensuring that
              they are built around the users to multiply the synergy between
              process, platforms, people and outcomes.
            </h4>
            <button className="ccsnl-btn" onClick={handleShow}>
              Book a Demo
            </button>
          </div>
          <div
            className="biz-sol-dsgn-img"
            data-aos="fade-left"
            data-aos-delay="1000"
          />
        </section>
        <section>
          <div className="biz-sol-cards-title" data-aos="fade-up">
            <h3>
              Customer <span>Experience</span> Analytics
            </h3>
            <h5>Continuous Performance Improvement</h5>
          </div>
          <div className="biz-sol-cards">
            <div
              className="biz-sol-card"
              data-aos="fade-up"
              data-aos-delay="100">
              <span>
                <FontAwesomeIcon icon={faTachometerAlt} size="3x" />
              </span>
              <p>
                Automate and track coaching based on Key Performance Indicator
                thresholds or achievement.
              </p>
            </div>
            <div
              className="biz-sol-card"
              data-aos="fade-up"
              data-aos-delay="400">
              <span>
                <FontAwesomeIcon icon={faChartLine} size="3x" />
              </span>
              <p>
                Allow supervisors to understand the root cause of performance
                issues across employees, teams, departments and time.
              </p>
            </div>
            <div
              className="biz-sol-card"
              data-aos="fade-up"
              data-aos-delay="700">
              <span>
                <FontAwesomeIcon icon={faTasks} size="3x" />
              </span>
              <p>
                Create cohesive, comprehensive views of employee performance
                based on real-time data from ACD, workforce management, quality
                management and other systems.
              </p>
            </div>
          </div>
        </section>
      </main>
      <section className="wrk-frc-optmztn">
        <div className="wrk-frc-optmztn-img" data-aos="fade-right" />
        <div
          className="wrk-frc-optmztn-info"
          data-aos="fade-left"
          data-aos-delay="700">
          <h3>Workforce Optimization</h3>
          <ul>
            <li>
              Workforce Optimization <br />
              Outcess offers Workforce optimization solutions designed to help
              organizations of all sizes reduce operating costs, improve
              performance, identify revenue opportunities, competitive
              advantages and transform the customer experience
            </li>
            <li>
              Customer Experience Analytics <br /> Automate and track coaching
              based on Key performance indicator thresholds or achievement
            </li>
          </ul>
        </div>
      </section>
      <main>
        <section>
          <div className="biz-apps-table-title" data-aos="fade-down">
            <h3>
              Business <span>productivity</span> applications ensure that
              organizations have the tools{" "}
              <span>to overcome the challenges</span> of executing strategies
              every day, and accelerating marketplace success.
            </h3>
            <h5>
              In today’s complex and competitive business landscape, using
              technology to maximize your business productivity creates the
              platform to realize true business success.
            </h5>
          </div>
          <div className="biz-apps-table">
            <div className="biz-apps-tbl-cntnt" data-aos="fade-down-right">
              <h4>Connect teams and Improve Collaboration</h4>
              <p>
                You will eliminate “corporate silos” that effectively cut off
                communication because employees can utilize technology to reach
                out to others and collaborate throughout the organization.
              </p>
            </div>
            <div className="biz-apps-tbl-cntnt" data-aos="fade-down-left">
              <h4>Monitor business productivity</h4>
              <p>
                Business productivity applications enable managers to easily
                track progress during every phase of goal completion and offer
                immediate reinforcement or coaching to keep performance and
                deadlines on track.
              </p>
            </div>
            <div className="biz-apps-tbl-cntnt" data-aos="fade-up-right">
              <h4>Analyze performance</h4>
              <p>
                Our Business Productivity solutions provide advanced reporting
                and business analytics capabilities to help you gain a deep
                understanding of business performance, customer preferences, and
                market trends. The resulting insights can be used collectively
                to improve performance.
              </p>
            </div>
            <div className="biz-apps-tbl-cntnt" data-aos="fade-up-left">
              <h4>Institutionalize process excellence</h4>
              <p>
                Using technology that establishes built-in workflows twill
                trigger a process-centric approach towards information seeking
                and sharing with role-based access.
              </p>
            </div>
          </div>
        </section>
        <section className="biz-sol-office365">
          <div className="biz-office365-img" data-aos="fade-right" />
          <div className="biz-office365-info">
            <h3>Office 365</h3>
            <p>
              Is a subscription based suite of business productivity
              applications enabled over the Internet (cloud services). The
              application features dedicated email hosting, communication,
              collaboration and meeting facilities as well as OneDrive, a high
              volume online storage facility. The Office 365 online plans are a
              great choice for business productivity, and are designed to work
              with the latest version of Office, Office 2013, and Office 2011
              for Mac.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BusinessProdSolution;
