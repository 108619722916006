import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RequestQuote from "../components/RequestQuote";
import Quoteimg from "../assets/img/qouteelementor.png";

const TransformCSOperationsCaseStudy = () => {
  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <div className="case_title_container">
        <main>
          <div className="case_title">
            <h1>
              Transforming Customer Service Operations for Insurance Companies
            </h1>
          </div>
        </main>
      </div>
      {/* Background Section */}
      <div className="custodian-case-study">
        <section className="multiChoice-background-section">
          <main>
            {/* <h2 className="multiChoice-section-title">MultiChoice</h2> */}
            <h3 className="multiChoice-subsection-title">Background</h3>
            <p className="multiChoice-text">
              A prominent provider of insurance products, offering solutions for
              auto, travel, home, life, and fire insurance. With a strong
              commitment to delivering reliable and affordable services, the
              company sought to strengthen its competitive edge while preparing
              for future growth.
            </p>
          </main>
        </section>
        <section className="case_study_first_challenge">
          <main>
            <h1>Challenge</h1>
            <p>
              The customer faced increasing customer service demands, compounded
              by fragmented communication across multiple channels. The lack of
              a unified platform led to delays and inconsistent customer
              experiences. Limited access to real-time data hindered performance
              monitoring and decision-making, while existing processes struggled
              to scale effectively with the company’s growth. Recognizing the
              need for transformation, The organization sought a partner to
              enhance its customer service operations, ensuring efficiency,
              scalability, and satisfaction.
            </p>
          </main>
        </section>
        <section className="multiChoice-approach-section">
          <main>
            <h2 className="multiChoice-section-title">Our Approach</h2>
            <p className="multiChoice-text">
              To address our customer’s challenges, we implemented a
              comprehensive solution that focused on optimising people, process,
              and technology.
            </p>
            <p className="multiChoice-text">
              We equipped our customer service team with the tools and training
              needed to excel in a dynamic service environment. Our agents were
              trained to leverage real-time insights and deliver consistent,
              personalized support, fostering a culture of proactive
              decision-making and continuous improvement.
            </p>
            <p className="multiChoice-text">
              Next, we streamlined the client's customer service processes by
              integrating all communication channels into a seamless omnichannel
              platform. This allowed our team to handle enquiries efficiently
              across phone, email, and social media, ensuring a cohesive and
              responsive service experience. Standardized workflows were
              introduced to enhance inquiry resolution, and performance metrics
              were established to monitor and drive service quality.
            </p>
            <p className="multiChoice-text">
              Finally, we deployed an advanced contact center solution to
              modernize the client's technology infrastructure. Automation and
              AI tools were integrated to reduce response times and improve
              issue resolution, while a robust analytics framework provided
              real-time visibility into customer interactions, agent
              performance, and satisfaction levels. The solution was designed to
              scale with the client's growth, ensuring it could meet future
              demands without compromising service quality.
            </p>
            <div className="multiChoice-approach-container">
          </div>
        </main>
      </section>
      <section className="multiChoice-outcomes-section">
        <main>
          <h2 className="multiChoice-section-title">Outcomes</h2>
          <h3>The transformation delivered measurable improvements across key areas:</h3>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Faster Response Times:</p>
            <p className="multiChoice-outcome-text">
            Significant reduction in query resolution time across all channels.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Enhanced Customer Satisfaction:</p>
            <p className="multiChoice-outcome-text">
            Personalized and efficient service led to higher satisfaction scores.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Optimized Operations:</p>
            <p className="multiChoice-outcome-text">
            Streamlined workflows and advanced analytics increased team productivity.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Future-ready Scalability:</p>
            <p className="multiChoice-outcome-text">
            The flexible solution ensured sustained service quality as the client's customer base expanded.
            </p>
          </div>
        </main>
      </section>
      </div>
      <section>
        <main>
          <div className="request_quote_container">
            <img src={Quoteimg} alt="Quote img" className="Quoteimg" />

            <div>
              <RequestQuote />
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default TransformCSOperationsCaseStudy;
