import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RequestQuote from "../components/RequestQuote";
import Quoteimg from "../assets/img/qouteelementor.png";

const FinTechCaseStudy = () => {
  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <div className="case_title_container7">
        <main>
          <div className="case_title">
            <h1>
            Transforming Customer Service for a Fintech Company
            </h1>
          </div>
        </main>
      </div>
      {/* Background Section */}

      {/* Background Section */}
      <section className="multiChoice-background-section">
        <main>
          {/* <h2 className="multiChoice-section-title">MultiChoice</h2> */}
          <h3 className="multiChoice-subsection-title">Background</h3>
          <p className="multiChoice-text">
          Our customer a leading fintech company approached us with challenges that required an innovative and scalable approach to customer support operations.
          </p>
        </main>
      </section>

      {/* The Challenge Section */}
      <section className="multiChoice-challenge-section">
        <main>
          <h2 className="multiChoice-section-title">The Challenge</h2>
          <p className="multiChoice-text">
           The customer faced a multitude of challenges spanning their people, processes, and technology.
          </p>
          <ul className="multiChoice-challenges-list">
            <li>
              <span className="multiChoice-challenge-icon">👥</span>
              <span className="multiChoice-challenge-text">
                People: From a people perspective, they struggled to ensure that their support agents were consistently trained to handle complex inquiries effectively while maintaining high service standards. Flexibility and resilience were also significant concerns, as their team lacked the capacity to adapt quickly to fluctuating demands, which often led to delayed responses and diminished service quality. Furthermore, there was a pressing need to align agent performance with clear targets for key metrics, including first response times and customer satisfaction scores (CSAT).
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-icon">⚙️</span>
              <span className="multiChoice-challenge-text">
                Processes: On the process front, the rapid growth of their user base and geographic expansion posed challenges in managing increasing volumes of customer enquiries. Ensuring consistent service quality across diverse channels, such as email, chat, social media, and voice, proved difficult. Additionally, the absence of defined escalation pathways led to unresolved issues, resulting in customer dissatisfaction.
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-icon">📊</span>
              <span className="multiChoice-challenge-text">
                Technology: Technologically, their existing systems was inadequate for managing inquiries efficiently across multiple communication channels. Limited analytics capabilities hindered their ability to collect, analyse, and forecast data, which was critical for informed decision-making. Finally, balancing technological investments with the need for operational cost efficiency remained an ongoing challenge.
              </span>
            </li>
          </ul>
        </main>
      </section>

      {/* Our Approach Section */}
      <section className="multiChoice-approach-section">
        <main>
          <h2 className="multiChoice-section-title">Our Approach</h2>
          <p className="multiChoice-text">
          To address our customer’s challenges, we implemented a comprehensive solution that focused on optimising people, process, and technology.
          </p>
          <div className="multiChoice-approach-container">
            <div className="multiChoice-approach-item">
              <h3 className="multiChoice-approach-title">People</h3>
              <p className="multiChoice-approach-text">
               We began by deploying highly trained support agents capable of managing enquiries across all channels, ensuring seamless customer interactions. Recognizing the complexity of the fintech sector, we developed customised training modules tailored to the client’s specific needs. This ensured agents were well-equipped to handle intricate customer enquiries. To maintain service consistency, we implemented a robust quality assurance framework that emphasised actionable feedback and continuous improvement.
              </p>
            </div>
            <div className="multiChoice-approach-item">
              <h3 className="multiChoice-approach-title">Processes</h3>
              <p className="multiChoice-approach-text">
                On the process side, we introduced a flexible staffing model designed to handle peak periods, such as product launches and marketing campaigns, without compromising service levels. Support workflows were meticulously streamlined to reduce bottlenecks and improve resolution times. Additionally, we established clear escalation protocols to ensure that unresolved issues were efficiently managed by internal teams, fostering greater customer trust and satisfaction.
              </p>
            </div>
            <div className="multiChoice-approach-item">
              <h3 className="multiChoice-approach-title">Technology</h3>
              <p className="multiChoice-approach-text">
                From a technology perspective, we implemented a state-of-the-art multichannel support system capable of managing inquiries across email, chat, social media, and voice platforms. Advanced analytics tools were deployed to collect and analyse customer interaction data, providing the client with actionable insights for better decision-making and forecasting. Lastly, we conducted a thorough assessment of operational costs, streamlining processes to ensure that all technological investments delivered maximum return on investment.
              </p>
            </div>
          </div>
        </main>
      </section>

      {/* Outcomes Section */}
      <section className="multiChoice-outcomes-section">
        <main>
          <h2 className="multiChoice-section-title">Outcomes</h2>
          <h3>The engagement delivered measurable results, significantly improving the client’s customer support operations. Key outcomes included:</h3>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Efficiency Gains:</p>
            <p className="multiChoice-outcome-text">
            Reduced first response times to an average of 2.5 minutes across written channels.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Scalability Achieved:</p>
            <p className="multiChoice-outcome-text">
               Seamlessly managed a 20% increase in inquiry volumes during peak demand periods.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Customer Satisfaction:</p>
            <p className="multiChoice-outcome-text">
              Surpassed the 80% CSAT target, consistently achieving 85%.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Cost Reduction:</p>
            <p className="multiChoice-outcome-text">
             Cost Reduction: Optimized operations across channels, reducing support costs by 15%.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Predictive Insights:</p>
            <p className="multiChoice-outcome-text">
               Enabled more effective capacity planning and campaign management through analytics-driven forecasting.
            </p>
          </div>
          <p className="mb-3">These results not only addressed the immediate operational challenges but also set the foundation for scalable growth, ensuring the client’s customer support systems could evolve alongside their business needs.</p>
          <p>In conclusion, Outcess understands the unique challenges of the fintech industry and the importance of delivering exceptional customer experiences. By combining expertise in people management, process optimisation, and technological innovation, we provided a solution that not only met but exceeded the client’s expectations. Our approach created a robust framework for future growth, enabling the client to focus on their core business and market expansion with confidence. Let us partner with you to navigate the complexities of scaling customer operations and achieve long-term success.</p>
        </main>
      </section>
      <section>
        <main>
          <div className="request_quote_container">
            <img src={Quoteimg} alt="Quote img" className="Quoteimg" />

            <div>
              <RequestQuote />
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default FinTechCaseStudy;
