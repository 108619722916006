import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RequestQuote from "../components/RequestQuote";
import Quoteimg from "../assets/img/qouteelementor.png";

const EnhancingTelehealthEngagementCaseStudy = () => {
  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <div className="case_title_container10">
        <main>
          <div className="case_title">
            <h1>
             Enhancing Telehealth Engagement
            </h1> 
          </div>
        </main>
      </div>
      {/* Background Section

      {/* Background Section */}
      <section className="multiChoice-background-section">
        <main>
          <h3 className="multiChoice-subsection-title">Background</h3>
          <p className="multiChoice-text">
            A leading provider of telehealth services, offering 24/7 access to healthcare through technology-driven platforms. Dedicated to bridging gaps in healthcare delivery, this organization focuses on timely medical consultations, app-based services, and innovative healthcare solutions.
          </p>
        </main>
      </section>

      {/* The Challenge Section */}
      <section className="multiChoice-challenge-section">
        <main>
          <h2 className="multiChoice-section-title">The Challenge</h2>
          <p className="multiChoice-text">
            Our customer faced challenges in managing a high volume of patient interactions while maintaining the quality of service. Delays in responding to enquiries and gaps in follow-ups affected patient satisfaction. Many users struggled with navigating the telehealth app, leading to inefficient appointment scheduling and missed consultations. Additionally, catering to a linguistically diverse audience added complexity to their operations. The customer then sought a partner to streamline engagement processes, ensure seamless communication, and provide scalable solutions to enhance patient satisfaction and trust.
          </p>
          
        </main>
      </section>

      {/* Our Approach Section */}
      <section className="multiChoice-approach-section">
        <main>
          <h2 className="multiChoice-section-title">Our Approach</h2>
          <p className="multiChoice-text">
          Outcess partnered with the client to transform their customer engagement and operational processes, focusing on delivering an exceptional telehealth experience.
          </p>
          <p className="multiChoice-text">We addressed the challenges by implementing a comprehensive solution:</p>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Enhancing Communication::</p>
            <p className="multiChoice-outcome-text">
              We streamlined our customer's communication channels to handle both inbound and outbound interactions efficiently. Agents were trained to manage high call volumes, answer enquiries, and address patient concerns promptly. Outbound calls focused on appointment confirmations, follow-ups, and patient engagement initiatives to ensure consistent care.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Supporting App Navigation:</p>
            <p className="multiChoice-outcome-text">
              Recognizing the technical challenges patients faced, our agents provided real-time guidance for using the client's telehealth app. From troubleshooting issues to navigating features, we ensured users could access services seamlessly and without interruptions.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Improving Appointment Management:</p>
            <p className="multiChoice-outcome-text">
              We simplified the booking process by integrating appointment scheduling through the app and phone support. Our agents followed up on patient requests, ensuring timely confirmations and reducing missed consultations.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Offering Multilingual Support:</p>
            <p className="multiChoice-outcome-text">
              To cater to a linguistically diverse patient base, we provided services in English, Yoruba, Igbo, and Hausa. This inclusivity expanded the client's accessibility, particularly among underserved communities, and reinforced their mission of patient-centric care.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Delivering Patient-Centric Solutions:</p>
            <p className="multiChoice-outcome-text">
              Our team aligned with the client's vision of accessible healthcare by handling each interaction with professionalism and empathy. We ensured that every touchpoint reflected the client's values of trust, innovation, and care.
            </p>
          </div>
        </main>
      </section>

      {/* Outcomes Section */}
      <section className="multiChoice-outcomes-section">
        <main>
          <h2 className="multiChoice-section-title">Outcomes</h2>
          <h3>The collaboration between Outcess and the client delivered measurable results:</h3>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Enhanced Patient Experience:</p>
            <p className="multiChoice-outcome-text">
             Streamlined communication processes improved query resolution times, leading to positive feedback from patients.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Efficient Appointment Management:</p>
            <p className="multiChoice-outcome-text">
              Optimized booking and follow-up processes increased adherence rates, reducing missed consultations.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Expanded Accessibility</p>
            <p className="multiChoice-outcome-text">
             Multilingual support reached new patient demographics, extending the client's impact in underserved regions.
            </p>
          </div>
        </main> 
      </section>
      <section>
        <main>
          <div className="request_quote_container">
            <img src={Quoteimg} alt="Quote img" className="Quoteimg" />

            <div>
              <RequestQuote />
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default EnhancingTelehealthEngagementCaseStudy;
