import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RequestQuote from "../components/RequestQuote";
import Quoteimg from "../assets/img/qouteelementor.png";

const TransformCSLoanRecoveryCaseStudy = () => {
  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <div className="case_title_container5">
        <main>
          <div className="case_title">
            <h1>
            Transforming Customer Service and Loan Recovery for Fintech Company
            </h1>
          </div>
        </main>
      </div>
      {/* Background Section */}

      {/* Background Section */}
      <section className="multiChoice-background-section">
        <main>
          <h3 className="multiChoice-subsection-title">Background</h3>
          <p className="multiChoice-text">
           Our customer, a rapidly growing fintech company, specialises in streamlining loan operations. As the company scaled, it faced significant challenges in managing customer service and loan recovery processes. With an increasing volume of customer interactions—spanning inbound and outbound calls, social media enquiries, and live chats—the company struggled to maintain operational efficiency while ensuring each customer interaction met high service standards. Additionally, the focus on loan collections created unique challenges, as agents had to handle sensitive customer data without relying on complex integrations with external systems.
          </p>
        </main>
      </section>

      {/* The Challenge Section */}
      <section className="multiChoice-challenge-section">
        <main>
          <h2 className="multiChoice-section-title">The Challenge</h2>
          <p className="multiChoice-text">
          Our customer faced a set of challenges that spanned across people, process, and technology, which were critical to their growth and success:
          </p>
          <ul className="multiChoice-challenges-list">
            <li>
              <span className="multiChoice-challenge-icon">👥</span>
              <span className="multiChoice-challenge-text">
                People: Our customer needed a highly skilled and trained workforce capable of managing sensitive loan recovery conversations with empathy and professionalism. They required agents who could handle a wide variety of customer enquiries—ranging from general support to complex loan recovery issues—while maintaining high service standards. The company also needed a partner that could scale operations efficiently without compromising the quality of service.
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-icon">⚙️</span>
              <span className="multiChoice-challenge-text">
                Processes: Our customer’s existing customer service and loan recovery processes were not optimized to handle the increasing volume of interactions. They needed streamlined workflows and standardised processes to ensure efficient communication and faster resolutions. Furthermore, the company faced challenges in integrating their loan recovery system with external tools, which complicated operations and slowed down response times.
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-icon">📊</span>
              <span className="multiChoice-challenge-text">
                Technology: Our customer’s technological infrastructure needed to support the growing volume of customer interactions across multiple channels, such as phone, social media, and chat, while ensuring that agents could work directly within the company’s platform. They also required robust data security and compliance measures to safeguard sensitive customer information, all while avoiding the need for complex system integrations.
              </span>
            </li>
          </ul>
        </main>
      </section>

      {/* Our Approach Section */}
      <section className="multiChoice-approach-section">
        <main>
          <h2 className="multiChoice-section-title">Our Approach</h2>
          <p className="multiChoice-text">
          To address our customer’s challenges, we implemented a comprehensive solution that focused on optimising people, process, and technology.
          </p>
          <div className="multiChoice-approach-container">
            <div className="multiChoice-approach-item">
              <h3 className="multiChoice-approach-title">People</h3>
              <p className="multiChoice-approach-text">
              We began by developing a people-centric approach. Recognizing the importance of skilled agents in managing sensitive loan recovery conversations, Outcess crafted a specialised training program tailored to our customer’s needs. Our agents were equipped with a deep understanding of their platform, products, and loan policies, enabling them to handle customer interactions with professionalism and empathy. This was especially crucial in loan recovery efforts, where a balance of effective communication and sensitivity is key to maintaining positive customer relationships. The training program ensured that our agents could address a wide variety of customer inquiries while consistently meeting high service standards.
              </p>
            </div>
            <div className="multiChoice-approach-item">
              <h3 className="multiChoice-approach-title">Processes</h3>
              <p className="multiChoice-approach-text">
              On the process side, Outcess worked to streamline and optimise the company’s customer service and loan recovery workflows. We introduced efficient communication protocols and standardised processes that helped reduce response times and ensured faster resolution of customer queries.
              These improvements were particularly significant in loan recovery, where timely and empathetic communication is essential. Additionally, we collaborated closely with our customer to define clear Service Level Agreements (SLAs), setting measurable targets for response times, resolution rates, and loan recovery outcomes.
              </p>
            </div>
            <div className="multiChoice-approach-item">
              <h3 className="multiChoice-approach-title">Technology</h3>
              <p className="multiChoice-approach-text">
              In terms of technology, Outcess deployed a scalable infrastructure designed to handle large volumes of customer interactions across various channels. Our solution supported omnichannel customer interactions enabling customers to reach our customer through their preferred method of communication—whether via phone, email, social media, or chat—while ensuring a seamless experience across these touchpoints. By seamlessly integrating our workflow into the customer’s platform, we eliminated the need for complex system integrations, maintaining the integrity of the company’s existing infrastructure. To safeguard sensitive customer data, we implemented robust security measures, including encryption and access controls, ensuring compliance with privacy regulations and data protection standards.
              </p>
            </div>
          </div>
        </main>
      </section>

      {/* Outcomes Section */}
      <section className="multiChoice-outcomes-section">
        <main>
          <h2 className="multiChoice-section-title">Outcomes</h2>
          <h3>The partnership with Outcess delivered significant improvements in our client’s operations:</h3>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Increased Operational Efficiency:</p>
            <p className="multiChoice-outcome-text">
             Outcess efficiently managed a high volume of interactions, ensuring that customer service quality remained high during peak periods, reducing wait times by 30% and increasing resolution speed by 25%.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Enhanced Customer Service:</p>
            <p className="multiChoice-outcome-text">
              Our trained agents delivered accurate, timely, and knowledgeable responses, improving customer satisfaction scores by 40% and boosting customer trust and retention.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Improved Loan Recovery:</p>
            <p className="multiChoice-outcome-text">
              Specialized loan recovery training led to a 35% increase in debt recovery rates, achieved through empathetic communication and effective issue resolution.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Seamless Workflow:</p>
            <p className="multiChoice-outcome-text">
             The direct integration of our agents into their platform improved workflow efficiency by 20%, eliminating the need for external tools or systems..
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Robust Data Security:</p>
            <p className="multiChoice-outcome-text">
             Outcess ensured 100% compliance with data protection regulations, safeguarding sensitive customer data and maintaining confidentiality.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Actionable Insights:</p>
            <p className="multiChoice-outcome-text">
              Daily reports allowed our customer to track performance metrics, leading to a 15% improvement in operational optimisation through data-driven decision-making.
            </p>
          </div>
          <p className="custodian-challenge-text">In conclusion, by partnering with Outcess, our customer was able to transform its customer service and loan recovery processes. Our tailored contact center solution provided scalable, secure, and efficient operations while meeting SLAs and maintaining high service standards. This collaboration helped our customer streamline operations, enhance customer experience, and focus on its core business objectives, leading to significant improvements in both customer satisfaction and loan recovery metrics.</p>
        </main>
      </section>
      <section>
        <main>
          <div className="request_quote_container">
            <img src={Quoteimg} alt="Quote img" className="Quoteimg" />

            <div>
              <RequestQuote />
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default TransformCSLoanRecoveryCaseStudy;
