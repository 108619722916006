import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RFQModal from "../components/RFQModal";

const BusinessOutsource = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <div id="business-outsource">
      <Navbar />

      <div className="bpo-intro">
        <div className="bpo-intr-containr">
          <div className="bpo-intr-contnt">
            <h4>
              Built from the experience of over <span>21,600,000</span>{" "}
              interactions per annum.
            </h4>
            <h4>
              Business Process Outsourcing works with organizations to help them
              become more agile and efficient.
            </h4>
            <button className="ccsnl-btn" onClick={handleShow}>
              Book a Demo
            </button>
          </div>
        </div>
      </div>
      <RFQModal show={show} setShow={setShow} />
      <main>
        <section className="bpo-core-cards">
          <div
            className="bpo-core-card"
            data-aos="fade-up"
            data-aos-delay="100">
            <div className="bpo-core-card-img1" />
            <div className="bpo-core-card-info">
              <h5>Managed Services</h5>
              <p>
                Our managed services model keeps your IT, Customer service, and
                sales processes running smoothly and without interruption.
              </p>
            </div>
          </div>
          <div
            className="bpo-core-card"
            data-aos="fade-up"
            data-aos-delay="400">
            <div className="bpo-core-card-img2" />
            <div className="bpo-core-card-info">
              <h5>Contact Center Outsourcing</h5>
              <p>
                Outcess is able to handle both your inbound and outbound calls,
                telemarketing campaigns from our large multipurpose contact
                center.
              </p>
            </div>
          </div>
          <div
            className="bpo-core-card"
            data-aos="fade-up"
            data-aos-delay="700">
            <div className="bpo-core-card-img3" />
            <div className="bpo-core-card-info">
              <h5>Data Processing</h5>
              <p>
                For Data Processing, we use technology to strategically capture
                and analyze data to meet your outsourcing goals.
              </p>
            </div>
          </div>
        </section>
        <section className="bpo-main-others">
          <div className="bpo-main-others-ai" data-aos="fade-left" />
          <div className="bpo-main-others-info">
            <h5>Transforming Customer Service with AI-Powered Solutions</h5>
            <p>
              We're leading the way in transforming customer service through 
              cutting-edge AI-powered contact center solutions. Our chatbots 
              and virtual assistants automate routine tasks, ensuring faster 
              response times and allowing your agents to focus on complex customer 
              needs. With predictive analytics and sentiment analysis, we tailor 
              interactions to your customers, providing proactive support and driving 
              satisfaction. We take pride in using AI to deliver exceptional 
              customer service, striking the perfect balance between automation 
              and the human touch for critical matters. Partner with Outcess to 
              elevate your customer service, reduce operational costs, and gain a 
              competitive edge in the market.{" "}
              <span>
                Outcess is a pioneer in revolutionizing customer service with 
                AI-driven contact center solutions, offering chatbots, virtual 
                assistants, predictive analytics, and sentiment analysis to enhance 
                customer interactions and reduce operational costs.
              </span>
            </p>
          </div>
        </section>
        <section className="bpo-main-others">
          <div className="bpo-main-others-info">
            <h5>Business Process Outsourcing</h5>
            <p>
              Business process outsourcing relieves you of the burden of trying
              to manage non-core business processes, while grappling with the
              day-to- day challenges of running your core business. Done
              efficiently, BPO will result in lower operating cost, higher
              productivity, enhanced customer experience, better business
              outcomes and growth.{" "}
              <span>
                Outcess Business Process Outsourcing creates a better landscape
                for rapid change in three ways:
              </span>
            </p>
          </div>
          <div className="bpo-main-others-img1" data-aos="fade-left" />
        </section>
        <section className="bpo-main-others">
          <div className="bpo-main-others-img2" data-aos="fade-right" />
          <div className="bpo-main-others-info">
            <ol>
              <li>
                <h5>Ensuring</h5>
                <p>
                  - new levels of efficiency, operational excellence and
                  productivity to drive business performance and growth.
                </p>
              </li>
              <li>
                <h5>Delivering</h5>
                <p>
                  - new ways to drive bigger cost reductions that free the
                  capital to fund innovation.
                </p>
              </li>
              <li>
                <h5>Bringing</h5>
                <p>
                  - more predictability to business outcomes and therefore,
                  greater resilience.
                </p>
              </li>
            </ol>
            <button className="ccsnl-btn" onClick={handleShow}>
              Book a Demo
            </button>
          </div>
        </section>
        <section className="bpo-main-others">
          <div className="bpo-main-others-info">
            <h5>Contact Center Outsourcing</h5>
            <p>
              Bundled Contact center Solutions with Microsoft Dynamics 365.{" "}
              <br />
              Central communications queuing across all agents, domestically and
              globally.
              <br />
              Pay-as- you-go flexibility (Turn CAPEX to OPEX).
              <br />
              Interactive voice response (IVR).
              <br />
              Deep business analytics from integration with Microsoft Dynamics
              CRM tools.
              <br />
              Automated call distribution and outbound dialling.
              <br />
              Computer telephony integration(CTI).
              <br />
            </p>
          </div>
          <div className="bpo-main-others-img3" data-aos="fade-left" />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BusinessOutsource;
