import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RequestQuote from "../components/RequestQuote";
import Quoteimg from "../assets/img/qouteelementor.png"; 
import airtelChallenge from "../assets/img/caseStudy/airtelChallenge.jpg"; 
const TelecomClientCaseStudy = () => {
  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <div className="case_title_container_two">
        <main>
          <div className="case_title">
            <h1>
              Transforming Telecom Customer Support: How Outcess Elevated
              Service Excellence with its Telecom Client.
            </h1>
          </div>
        </main>
      </div>
      {/* Background Section */}
      <section className="background">
        <main>
          <div className="container">
            <h2>Background</h2>
            <p>
              A leading global telecommunications provider, recognised for driving digital
              transformation, sought a strategic partner to address operational challenges
              in its contact center operations. The company needed a partner to recruit
              skilled personnel, provide proactive leadership, and ensure operational
              efficiency to meet growing demands in a highly competitive market.
            </p>
          </div>
        </main>
      </section>

      {/* Challenge Section */}
      <section className="challenge">
        <main>
          <div className="container">
            <h2>The Challenge</h2>
            <p className="mb-4">
            The telecom provider faced several operational challenges that impacted
            service continuity and customer satisfaction:
            </p>
            <div className="challenge-details">
              <div className="challenge-text">
                <ul>
                  <li>
                    <strong>High Employee Turnover:</strong> Frequent staff changes disrupted consistency
                    and productivity.
                  </li>
                  <li>
                    <strong>Inconsistent Service Quality:</strong> Variability in customer interactions
                    affected satisfaction levels.
                  </li>
                  <li>
                    <strong>Limited Quality Assurance Processes:</strong> Inadequate QA monitoring led to
                    inefficiencies and service inconsistencies.
                  </li>
                  These issues created roadblocks as the company scaled its contact center operations. A comprehensive solution was required to stabilise the workforce,
                  improve service delivery, and implement proactive management practices.
                </ul>
              </div>
              <div className="challenge-image" >
                <img src={airtelChallenge} alt="Quote img" className="Quoteimg" />
              </div>
            </div>
          </div>
        </main>
      </section>

              {/* Our Approach Section */}
      <section className="custodian-approach-section">
          <main>
            <h2 className="custodian-section-title">Our Approach</h2>
            <p className="custodian-text">
            Outcess implemented a customised strategy to transform the telecom provider’s contact center operations. By addressing challenges in people, processes, and technology, we ensured long-term stability and efficiency.
            </p>
            <div className="custodian-approach-container">
              <div className="custodian-approach-item">
                <h3 className="custodian-approach-title">People</h3>
                <p className="custodian-approach-text mb-3">
                Outcess prioritised building a skilled and motivated workforce to meet the client’s service delivery goals:
                </p>
                <ul className="custodian-challenges-list"></ul>
                <li className="mb-3">
                <span className="custodian-challenge-text">
                Targeted Recruitment: Carefully selected personnel aligned with the company’s service standards
                </span>
                </li>
                <li className="mb-3">
                <span className="custodian-challenge-text">
                Rigorous Training Programs: Agents were trained to deliver exceptional customer experiences, creating a customer-centric culture throughout the contact center.
                </span>
                </li>
                <li>
                <span className="custodian-challenge-text">
                Proactive Leadership: Experienced team leads and managers were recruited to drive continuous improvement, align operations with company objectives, and foster accountability.
                </span>
                </li>
              </div>
              <div className="custodian-approach-item">
                <h3 className="custodian-approach-title">Processes</h3>
                <p className="custodian-approach-text mb-3">
                To enhance service consistency and operational efficiency, Outcess developed and implemented standardised workflows:
                </p>
                <ul className="custodian-challenges-list"></ul>
                <li className="mb-3">
                <span className="custodian-challenge-text">
                Quality Assurance Integration: Dedicated QA personnel monitored customer interactions, providing actionable feedback to ensure consistent service quality.
                </span>
                </li>
                <li className="mb-3">
                <span className="custodian-challenge-text">
                Proactive Management Practices: Structured processes empowered team leads and managers to swiftly address challenges, maintain performance standards, and reduce service disruptions.
                </span>
                </li>
                <li>
                <span className="custodian-challenge-text">
                Employee Retention Strategies: Initiatives to boost employee satisfaction and retention created a more stable and experienced workforce.
                </span>
                </li>
              </div>
              <div className="custodian-approach-item">
                <h3 className="custodian-approach-title">Technology</h3>
                <p className="custodian-approach-text mb-3">
                Outcess introduced advanced technology solutions to drive efficiency and provide visibility into performance metrics:
                </p>
                <ul className="custodian-challenges-list mb-3"></ul>
                <li  className="mb-3">
                <span className="custodian-approach-text">
                Advanced Reporting Tools: Real-time analytics tracked agent performance, customer satisfaction, and service quality, enabling data-driven decisions.
                </span>
                </li>
                <li>
                <span className="custodian-challenge-text">
                Performance Tracking Systems: Tools were implemented to evaluate team productivity and identify areas for improvement, ensuring continuous optimisation.
                </span>
                </li>
              </div>
            </div>
          </main>
      </section>

      {/* Outcomes Section */}
      <section className="outcomes">
        <div className="container">
          <h2>Outcomes</h2>
          <p className="mb-5">
          Outcess’s tailored approach delivered transformative results:
          </p>
          <div className="outcomes-details">
            <div className="outcome-item">
              <strong>Reduced Turnover: </strong>
              Recruitment strategies and proactive management stabilised the workforce, ensuring a consistent team of skilled
               personnel.
            </div>
            <div className="outcome-item">
              <strong>Improved Service Quality: </strong>Quality assurance efforts and leadership
               enhancements resulted in higher-quality customer interactions and
               improved satisfaction scores.
            </div>
            <div className="outcome-item">
              <strong>Stronger Leadership: </strong>Proactive and experienced managers ensured
              high service standards and consistent performance improvement.
            </div>
            <div className="outcome-item">
              <strong>Optimised Performance Monitoring:</strong>
              Advanced analytics enabled realtime tracking of key metrics, supporting operational excellence and
              informed decision-making.
            </div>
            <p className="mb-3">By addressing challenges across people, processes, and technology, Outcess
            empowered the telecom provider to scale its contact center operations
            while maintaining exceptional service standards and improving customer
            satisfaction.</p>
          </div>
        </div>
      </section>
      <section>
        <main>
          <div className="request_quote_container">
            <img src={Quoteimg} alt="Quote img" className="Quoteimg" />

            <div>
              <RequestQuote />
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default TelecomClientCaseStudy;
