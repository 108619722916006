import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Blogs from "../components/Blogs";
import { NavLink } from "react-router-dom";
import LandingCarousel from "../components/LandingCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBusinessTime,
  faChartPie,
  faServer,
  faChartBar,
  faPeopleArrows,
} from "@fortawesome/free-solid-svg-icons";
import OurPartners from "../components/OurPartners";
import video from "../assets/video/Outcess-video.mp4";
import ReactPlayer from "react-player";
import Analytics from "../components/Analytics";

const Home = () => {
  const [next, setNext] = useState(false);
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // useEffect(() => {
  //   handleShow()
  // }, [])

  const onClick = (e) => {
    e.preventDefault();
    setNext(true);
  };

  return (
    <div id="home">
      <Navbar />
      <LandingCarousel />

      <main>
        <section>
          <h3 data-aos="fade-in">
            Projects that make us <span>Stand Out</span>
          </h3>
          <div className="standout-projects">
            <div className="dept-recovery" data-aos="fade-right">
              <p>Fintech</p>
              <p>BPO</p>
              <p>
                Debt Recovery - They had issues in getting their customers who
                borrowed money to pay back.
              </p>
              <NavLink to="/business-process-outsourcing" exact>
                <button>Read More</button>
              </NavLink>
            </div>
            <div
              className="customer-engagement"
              data-aos="fade-left"
              data-aos-delay="500">
              <p>Government Agency</p>
              <NavLink to="/casestudiessubpages/10/details" exact>
                <button>Read More</button>
              </NavLink>
            </div>
          </div>
        </section>
        <section>
          <h3 data-aos="fade-in">
            Services we <span>Deliver</span>
          </h3>
          <div className="services-deliver">
            <NavLink
              className="services-card"
              to="/business-productivity-solutions"
              exact
              data-aos="fade-up">
              <div className="services-card-inner">
                <span>
                  <FontAwesomeIcon icon={faBusinessTime} />
                </span>
                <h5>Business Productivity Solutions</h5>
                <p>
                  To boost business organisation's productivity, Outcess takes
                  an enterprise- wide approach to build an empowered and agile
                  workplace that maximizes employee output and satisfaction, and
                  delivers sustainable value to customers.
                </p>
                <button>Discover now</button>
              </div>
            </NavLink>
            <NavLink
              className="services-card"
              to="/business-process-outsourcing"
              exact
              data-aos="fade-up">
              <div className="services-card-inner">
                <span>
                  <FontAwesomeIcon icon={faChartPie} />
                </span>
                <h5>Business Process Outsourcing</h5>
                <p>
                  Outcess Business Process Outsourcing works with organisations
                  to help them become more agile and efficient. By allowing you
                  to prioritize on your core business and focus on outsourcing
                  the right business processes.
                </p>
                <button>Discover now</button>
              </div>
            </NavLink>
            <NavLink
              className="services-card"
              to="/customer-engagement-Solutions"
              exact
              data-aos="fade-up">
              <div className="services-card-inner">
                <span>
                  <FontAwesomeIcon icon={faPeopleArrows} />
                </span>
                <h5>Customer Engagement Solutions</h5>
                <p>
                  Outcess Customer Engagement Services offer an integrated set
                  of digital solutions to help organisations create engaging
                  customer experiences and achieve superior customer retention
                  and growth results.
                </p>
                <button>Discover now</button>
              </div>
            </NavLink>
            <NavLink
              className="services-card"
              to="/cloud-solutions"
              exact
              data-aos="fade-up">
              <div className="services-card-inner">
                <span>
                  <FontAwesomeIcon icon={faServer} />
                </span>
                <h5>Cloud Computing</h5>
                <p>
                  Outcess is well versed in deploying a variety of critical
                  cloud services platforms, including hybrid clouds that give
                  you the best of both worlds. We help drive cost and time
                  efficiencies, enhance flexibility and ensure security and
                  compliance for mission‑critical applications.
                </p>
                <button>Discover now</button>
              </div>
            </NavLink>
            <NavLink
              className="services-card"
              to="/omni-channel-marketing"
              exact
              data-aos="fade-up">
              <div className="services-card-inner">
                <span>
                  <FontAwesomeIcon icon={faChartBar} />
                </span>
                <h5>Omni-Channel Digital Marketing</h5>
                <p>
                  SmartMessage, we provide an enterprise-ready, GDPR compliant
                  omni-channel communication platform which allows the creation,
                  automation, execution and reporting of various channels such
                  as Email, SMS, MMS, Push, etc.
                </p>
                <button>Discover now</button>
              </div>
            </NavLink>
          </div>
        </section>
      </main>

      <Analytics />

      <main>
        <section>
          <div className="our-company">
            <div className="our-company-details" data-aos="fade-right">
              <h3>
                Our <span>Company</span>
              </h3>
              <h5>
                For over 15 years we've been able to compete, grow and prosper
                in an accelerating world.
              </h5>
              <p>
                Outcess is an integrated customer engagement and business
                process outsourcing company. We deliver innovative digital
                solutions that empower and optimize the performance of people,
                teams and organizations.
              </p>
              <NavLink to="/about" exact>
                <button>Find Out More</button>
              </NavLink>
            </div>

            {next ? (
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  // url='https://youtu.be/UL_Gc5CpPzc'
                  url={video}
                  width="100%"
                  height="100%"
                  playing="true"
                  controls="true"
                  playsinline="true"
                />
              </div>
            ) : (
              <div className="our-company-img" data-aos="fade-left">
                <a
                  href
                  id="play-video"
                  onClick={onClick}
                  class="video-play-button">
                  <span></span>
                </a>
              </div>
            )}
          </div>
          <div></div>
        </section>
        <section>
          <div className="our-partners" data-aos="fade-in">
            <h3>
              Articles in this <span> Edition</span>
            </h3>
          </div>
          <br />
          <div data-aos="fade-up">
            <Blogs />
          </div>
        </section>
        <section>
          <div className="our-partners" data-aos="fade-in">
            <h3>
              Our <span>Partners</span>
            </h3>
          </div>
          <br />
          <div data-aos="fade-up">
            <OurPartners />
          </div>
        </section>
      </main>
      <Footer />

      {/* 
        <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="md"
            >
              <Modal.Header closeButton>
                <Modal.Title>Fidelity International Trade & Creative Connect</Modal.Title>
              </Modal.Header>
              
              <Modal.Body>
                <div className="fidelty">
                  <img src={mdSpeech} alt="fidelitySpeech"/>
                </div>
                
              </Modal.Body>
              
               <Modal.Footer>
              
                <a href="https://whova.com/portal/registration/fitcc_202310/" target="_blank" rel="noreferrer" crossOrigin="anonymous">
                  <Button variant="secondary" className="fidelity-footer"> 
                  Register for this event
                </Button>
                </a>
               
                
               
        </Modal.Footer>
              
        

              
      </Modal> */}
    </div>
  );
};
export default Home;
