import commission from "../assets/img/commission.jpg";
import choice from "../assets/img/choice.jpg";
import vodacom from "../assets/img/vodacom.jpg";
import mtn from "../assets/img/mtn.png";
import mobile from "../assets/img/9mobile.jpg";
import hb from "../assets/img/hb.png";
// import gtbank from '../assets/img/gtBank.png';
import airtel from "../assets/img/airtel.jpg";
import fidelity from "../assets/img/fidelity.png";
import sterling from "../assets/img/sterling.png";
import billions from "../assets/img/billions.png";
import total from "../assets/img/total.png";
import cscs from "../assets/img/cscs.png";
import branch from "../assets/img/branch.png";
import ipnx from "../assets/img/ipnx.png";
import mutualbenefit from "../assets/img/mutual-benefit.jpg";
import sunKing from "../assets/img/sunKing.jpg";
import renMoney from "../assets/img/renMoney.jpg";
import asoSaving from "../assets/img/asoSaving.jpg";
import NIS from "../assets/img/NIS.jpg";
import flaim from "../assets/img/flaim.png";
import fairmoney from "../assets/img/fairmoney.png";
import custodian from "../assets/img/custodian.jpg";
import rescue from "../assets/img/rescue.jpg";
import Landwey from "../assets/img/Landwey.png";
import NLPC from "../assets/img/NLPC.png";
import mecure from "../assets/img/mecure.jpg";
import smile from "../assets/img/smile.jpg";
import fluterwave from "../assets/img/fluterwave.png";
import bagasoo from "../assets/img/bagasoo.png";
import wakanow from "../assets/img/wakanow.jpg";
import leadCapital from "../assets/img/leadCapital.jpg";
import CAT from "../assets/img/CAT.jpg";
import unifiedPayments from "../assets/img/unifiedPayments.jpg";
import cadbury from "../assets/img/cadbury.jpg";
import embassy from "../assets/img/christ-embassy.png";
import swift from "../assets/img/swift.jpg";
import access from "../assets/img/access-bank.jpg";
import ntel from "../assets/img/n-tel.png";
import jumia from "../assets/img/jumia.png";
import customer1 from "../assets/img/customer-satisfaction.jpeg";
import customer2 from "../assets/img/focus.jpeg";
import customer3 from "../assets/img/work-look.jpeg";
import customer4 from "../assets/img/talk.jpeg";
import Ameyo33 from "../assets/img/ameyo1.jpeg";
import uconnect from "../assets/img/uconnect.png";
import smartsms from "../assets/img/parts2.jpg";
import nexFortris from "../assets/img/nexFortris.png";
import microsoft from "../assets/img/Microsoft1.png";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { RiCustomerService2Line } from "react-icons/ri";
import { FaServer } from "react-icons/fa";
import airtelF from "../assets/img/caseStudy/airtelFF.jpg";
import inq from "../assets/img/caseStudy/inq.jpg";
// import custodianC from "../assets/img/caseStudy/custodianC.jpg";
import multichoiceNeww from "../assets/img/caseStudy/multichoiceNeww.jpg";
import lendSqrr from "../assets/img/caseStudy/lendSqrr.jpg";
import financeN from "../assets/img/caseStudy/financeN.jpg";
import FinTechN from "../assets/img/caseStudy/FinTechN.jpg";
import debtN from "../assets/img/caseStudy/debtN.jpg";
import cscsB from "../assets/img/caseStudy/cscsB.jpg";
import healthN from "../assets/img/caseStudy/healthN.jpg";
import custodianN from "../assets/img/caseStudy/custodianN.jpg";

export const subNavData = [
  {
    id: 1,
    header: "",
    title: "Building High-Performing Contact Centers",
    // eslint-disable-next-line
    category: "Telecommunications",
    text: "A global leader in digital transformation, prides itself on delivering cutting-edge solutions that empower businesses worldwide.",
    img: inq,
    link: "/high-performance-contact-center",
  },

  {
    id: 2,
    header: "",
    title: "Transforming Telecom Customer Support",
    // eslint-disable-next-line
    category: "Telecommunications",
    text: "A leading telecommunications provider in Nigeria, is well-known for its expansive network coverage and customer-centric services.",
    img: airtelF,
    link: "/transform-customer-support",
  },

  {
    id: 12,
    header: "",
    title: "Driving Subscription Loyalty",
    // eslint-disable-next-line
    category: "Entertainment",
    text: "A leading entertainment provider, faced growing challenges in managing its subscriber base amidst increasing competition and customer expectations.",
    img: multichoiceNeww,
    link: "/driving-subscription-loyalty",
  },
  {
    id: 13,
    // header: "CUSTODIAN",
    title: "Transforming Customer Service Operations for Insurance Companies",
    category: "Insurance",

    text: "A prominent provider of insurance products, offering solutions for auto, travel, home, life, and fire insurance.",
    img: custodianN,
    link: "/transforming-customer-service-operations",
  },

  {
    id: 3,
    title: "Transforming Customer Service and Loan Recovery for a Fintech Company",
    category: "Fintech",
    text: "A rapidly growing fintech company that specialises in streamlining loan operations.",
    img: lendSqrr,
    link: "/transforming-loan-recovery-customer-service",
  },

  {
    id: 4,
    // header: "CUSTODIAN",
    title: "Enhancing Customer Support and Operational Efficiency for a financial technology company.",
    category: "Fintech",
    text: "A leading digital savings platform, experienced rapid growth, resulting in a surge of customer enquiries.",
    img: financeN,
    link: "/enhance-customer-service-and-operational-efficiency",
  },

  {
    id: 5,
    // header: "CUSTODIAN",
    title: "Enhancing Customer Support and Operational Efficiency for a Fintech company.",
    category: "Fintech",
    text: "A leading fintech company approached us with challenges that required an innovative and scalable approach to customer support operations.",
    img: FinTechN,
    link: "/fintechcasestudy",
  },

  {
    id: 6,
    // header: "CUSTODIAN",
    title: "Revolutionizing Debt Recovery for a Leading Financial Institution.",
    category: "Fintech",
    text: "One of Nigeria's foremost financial institutions, partnered with Outcess to enhance its debt recovery processes.",
    img: debtN,
    link: "/revolutionizing-debt-recovery-for-financial-institution",
  },

  {
    id: 7,
    // header: "CUSTODIAN",
    title: "Transforming Customer Support for a Scalable Cloud and Contact Center.",
    category: "Cloud Solutions",
    text: "One of Nigeria’s premier financial market infrastructure licensed and regulated by the SEC",
    img: cscsB,
    link: "/transform-contact-center-and-customer-support",
  },

  {
    id: 8,
    // header: "CUSTODIAN",
    title: "Enhancing Telehealth Engagement",
    category: "Health",
    text: "A leading provider of telehealth services, offering 24/7 access to healthcare through technology-driven platforms.",
    img: healthN,
    link: "/enhancing-telehealth-engagement",
  }
];

export const workData = [
  {
    text1:
      "Boost Customer Service with Industry’s First Enterprise-Grade Mobile Call Center Solution. Ameyo promises an enhanced customer experience for businesses with limited contact center infrastructure – The Ameyo Mobile Agent. An industry- first mobile application with enterprise - ready call center features. The application is powered by mobile WebRTC and ensures high call quality, access to customer details via CTI, dialers for wide outreach, faster deployment, remote agent monitoring and all this within a Mobile App. Zero Agent Infrastructure Needed | Enterprise - Grade Features | Comprehensive Remote Monitoring.",
  },
  {
    text2:
      "Manage Complete Contact Center Operations with a Feature-Rich Solution Let the agent handle customer service requests while being in the safety of their homes.Ameyo offers VPN access combined with WebRTC to ensure high conversation quality in a secure environment.Additionally, with Ameyo’s remote call center software, enjoy comprehensive reports, and live monitoring tools to enable supervisors to remotely monitor agent performance along with getting complete activity logs(calls, notes, dispositions, etc).Secure VPN Access | WebRTC for Call Quality | Extensive Monitoring",
  },
  {
    text3:
      "Enable Contact Center Interactions via an IVR/ Feature Phone Do not let the absence of smartphones, laptops or a stable internet connection come in the way of delivering quality customer service.Ameyo’s Phone Agent functionality enables contact center operations via an IVR.Agents get to have complete call functionality like click- to - dial, hold, mute, transfer, conference, etc on their feature phone itself while all the calls are recorded on the server for security and monitoring purposes which the supervisor can access at any time to track KPIs and SLAs with remote call center software. Portable Agent Workbench | 100 % Call Monitoring | Secure Customer Data.",
  },
];

export const faqHeadersData = [
  {
    header1: "We are here to answer all questions",
    para1:
      "Having issues or difficulties accessing or understanding our services?",
    para2: "Here are few questions that are recently asked.",
    header2: "General Frequently Asked ",
    para3:
      "We aim to help your business through providing services you need and also provide answers about our products & services.",
  },
];

export const faqData = [
  {
    id: 1,
    header1: "What services will you be providing?",
    text: "At Outcess we provide the following solutions: - Business Process Outsourcing and Customer Engagement - We deliver successful customer acquisition, retention and growth for our clients by leveraging technology and software solutions.",
  },
  {
    id: 2,
    header1: "How you intend to provide the customer satisfaction?",
    text: "Research has it that attracting a new customer costs 5 times more than keeping existing ones. We understand the benefits of satisfying current customers, which is why we strongly support Customer Engagement. We provide every means possible through which customers can reach out to companies and have their issues resolved at the best time possible.",
  },
  {
    id: 3,
    header1: "Does this service of yours also apply to small scale businesses?",
    text: "Our unique service spreads out to diverse businesses, regardless of the type, size or particular sector of the economy they fall into.",
  },
  {
    id: 4,
    header1: "How can we help your business?",
    text: "Through the collaboration with customers in discussing needs and demand, we attain mutual understanding, gain customer trust to offer appropriate advice, and bring about suggestions on suitable technology to transform your busines",
  },
  {
    id: 5,
    header1: "What are the advantages of Outcess?",
    text: "Outcess takes into consideration every little detail to make sure the system run smoothly and responsively. Outcess employs a new technique called Unified Technology for securing customers' database &amp; building up highly confidential firewalls.",
  },
];

export const outcessClientsData = [
  {
    img: commission,
  },
  {
    img: choice,
  },
  {
    img: vodacom,
  },
  {
    img: mtn,
  },
  {
    img: mobile,
  },
  {
    img: hb,
  },
  // {
  //     img: gtbank,
  // },
  {
    img: airtel,
  },
  {
    img: fidelity,
  },
  {
    img: sterling,
  },
  {
    img: billions,
  },
  {
    img: total,
  },
  {
    img: cscs,
  },
  {
    img: branch,
  },
  {
    img: ipnx,
  },
  {
    img: mutualbenefit,
  },
  {
    img: sunKing,
  },
  {
    img: renMoney,
  },
  {
    img: asoSaving,
  },
  {
    img: NIS,
  },
  {
    img: flaim,
  },
  {
    img: fairmoney,
  },
  {
    img: custodian,
  },
  {
    img: rescue,
  },
  {
    img: Landwey,
  },
  {
    img: NLPC,
  },
  {
    img: mecure,
  },
  {
    img: smile,
  },
  {
    img: fluterwave,
  },
  {
    img: bagasoo,
  },
  {
    img: wakanow,
  },
  {
    img: leadCapital,
  },
  {
    img: CAT,
  },
  {
    img: unifiedPayments,
  },
  {
    img: cadbury,
  },
  {
    img: embassy,
  },
  {
    img: swift,
  },
  {
    img: access,
  },
  {
    img: ntel,
  },
  {
    img: jumia,
  },
];

export const OmniData = [
  {
    h4: "Flexible and scalable platform",
    p: "SmartMessage supports on-premise, cloud or hybrid deployments. With this scalable platform, you’ll improve the total cost of ownership, deliver better results and be ready for future challenges.",
  },
  {
    h4: "Built-in house, not bought",
    p: "SmartMessage platform was built from the ground up to meet the evolving needs of businesses around the world. All the modules offered were built-in house and are natively integrated.",
  },
  {
    h4: "Extensive integration capabilities",
    p: "Elevate your organizational agility by using SmartMessage connectors and API’s to facilitate the integration of your existing business applications including legacy systems with SmartMessage Marketing Platform.",
  },
  {
    h4: "A unique maturity models",
    p: "Our flexible technology, modular services packages, and profile and channel-based pricing model allow you to start now and grow with SmartMessage as you expand your marketing maturity.Deliverability services If you are struggling with understanding what is affecting your inbox placement and ultimately the success of your emails, we have an inhouse team available to help you.",
  },
  {
    h4: "Deliverability Consultation",
    p: "– Sender Score Certification application process",
    p1: "– Sender Reputation Management – Continuous monitoring, support and monthly reporting services to ensure that your deliverability is managed as carefully as possible.",
  },
  {
    h4: "Support services",
    p: "Ongoing support for technology investments is critical, and needs vary from organization to organization. Our support team provides comprehensive support for companies of all sizes—from day- to-day incident resolution to operational support and system monitoring.",
    p1: "–Choose the support plan that’s right for you – Gold, Platinum, or Enterprise",
    p2: "– 24 / 7 dedicated hotline for critical troubleshooting is available upon request",
    p3: "– Fully managed services for campaign planning, execution and reporting services.",
  },
  {
    h4: "360 Degree Customer Profile",
    p: "Profiles are elastically searchable and available in the UI and as an API to be leveraged across the enterprise. Provide a single source of truth for all your customer-facing teams. ",
    p1: "–Using channels, devices , campaign responses , purchase activity , product interests and location to determine future customer behavior. ",
  },
  {
    h4: "Behavioral Clustering",
    p: "Empower customers to manage their own experience through self- service preference centers that enable them to manage their communication preferences under GDPR and other regional data protection regulations.",
  },
  {
    h4: "Data Collection & Unification",
    p: "Understand and speak to your customers as individuals, teams and organizations through a single unified customer profile. Outcess continuously collects data and signals from all sources, channels and devices including anonymous identifiers. This data is aggregated to create a complete, living unified customer profile that is essential to powering your marketing decisions and orchestration.",
  },
  {
    h4: "Deployment Services",
    p: "Our experienced, skilled team comprised of technical, strategy, product and project management experts will ensure your successful implementation and full use of our Marketing Platform.",
    p1: "– Deploy quickly and reliably anywhere in the world",
    p2: "– Accelerate time to value and minimize risk.",
    p3: "– Accelerate user adoption with onsite technical and end - user training.",
  },
  {
    h4: "Give Customers control",
    p: "Using channels, devices, responses, purchase activity, product interests and location to determine future customer behavior.",
  },
  {
    h4: "Data Collection & Unification",
    p: "Empower customers to manage their own experience.",
  },
];

export const Omni = [
  {
    img: customer1,
    h42: "Data Collection & Unification",
    p: "Understand and speak to your customers as individuals, teams and organizations through a single unified customer profile. Outcess continuously collects data and signals from all sources, channels and devices including anonymous identifiers. This data is aggregated to create a complete, living unified customer profile that is essential to powering your marketing decisions and orchestration.",
  },
  {
    img: customer2,
    h42: "Deployment Services",
    p: "Our experienced, skilled team comprised of technical, strategy, product and project management experts will ensure your successful implementation and full use of our Marketing Platform.",
    p1: "– Deploy quickly and reliably anywhere in the world",
    p2: "– Accelerate time to value and minimize risk.",
    p3: "– Accelerate user adoption with onsite technical and end - user training",
  },
  {
    img: customer3,
    h42: "Give Customers control",
    p: "Using channels, devices, responses, purchase activity, product interests and location to determine future customer behavior.",
  },
  {
    img: customer4,
    h42: "Data Collection & Unification",
    p: "Collect, manage and organize limitless quantities of customer data and signals from any source using multiple import methods including sFTP, real-time streaming, connectors, API’s, web forms and manual uploads.",
  },
];

export const BPData = [
  {
    img: microsoft,
    h1: "Microsoft Corporation was  ",
    h12: "  incorporated on ",
    h13: "  September 22, 1993.",
    h2: "Microsoft",
    p: "It is a technology company that develops licenses and supports a range of software products which include office 360 commercial such as Office Exchange, SharePoint, and Skype for Business and related Client Access Licenses (CALs), Outlook.com and OneDrive, and Dynamics business solutions, including Dynamics ERP products, Dynamics CRM on-premises, and Dynamics CRM Online, and other services and devices.",
  },
  {
    img: smartsms,
    h1: "SmartMessage was founded in 2005, a pioneer in the email marketing and",
    h12: " enterprise communication",
    h13: "  industry.",
    h2: "Smart Message",
    p: "Throughout our journey, we’ve explored the challenges that disabled our customers from realizing their full potential and the barriers that complicated their move towards digital transformation.",
  },
  {
    img: Ameyo33,
    h1: "Ameyo is a leading  ",
    h12: "  Omnichannel customer engagement  ",
    h13: "  technology company.",
    h2: "Ameyo",
    p: "Ameyo is an Omnichannel customer engagement platform that helps businesses go remote with its 3 Unique Work from Home Solutions and help them streamline their customer service, customer support, and collection processes. Ameyo’s robust platform is available for on-cloud and on-premise implementation with private, public, as well as hybrid instances. It has pre-built integrations with all significant industry-grade CRMs. Ameyo provides strong omnichannel capabilities of Voice, IVR, ACD, Dialers, Email, Chat, and Social Media such as Instagram, Google Play Store, Twitter, Facebook & WhatsApp.",
  },
  {
    img: nexFortris,
    h1: "NetFortris delivers secure, ",
    h12: "reliable communication solutions, ",
    h13: "customized to meet the unique needs of mid-market and enterprise customers.",
    h2: "NetFortris",
    p: "NetFortris cloud solutions, carried over our private, nationwide MPLS network, include SD WAN, Voice & UCaaS, Network and Data Security and 4G backup. Not only do we manage these services with, or on behalf of our customers, we consolidate billing to streamline their operation. Customized Solutions, Secure and Reliable, Geared toward Growth, that’s Business Communications Simplified. That’s NetFortris.",
  },
  {
    img: uconnect,
    h1: "Uconnect was founded in 2004, a  ",
    h12: "  Human resource consulting company  ",
    h13: "  with head office in Lekki peninsula, Lagos.",
    h2: "Uconnect",
    p: "We are a proud Nigerian operation offering local support and services in the area of Human resource solutions with service offerings which include world class personnel outsourcing, e-recruitment, head hunting, training and development, quality management solutions, outplacements, payroll administration, computer based pre-assessment and competency testing as well as Business process outsourcing.",
  },
];

export const wfhData = [
  {
    id: 1,
    icon: <RiCustomerService2Line />,
    h4: "Customer Service",
    textp:
      "Boost Customer Service with Industry’s First Enterprise-Grade Mobile Call Center Solution. Ameyo promises an enhanced customer experience for businesses with limited contact center infrastructure – The Ameyo Mobile Agent.",
  },
  {
    id: 2,
    icon: <IoPhonePortraitOutline />,
    h4: "Mobile Application",
    textp:
      "An industry- first mobile application with enterprise - ready call center features. The application is powered by mobile WebRTC and ensures high call quality, access to customer details via CTI, dialers for wide outreach, faster deployment, remote agent monitoring and all this within a Mobile App.",
  },
  {
    id: 3,
    icon: <FaServer />,
    h4: "Zero Infrastructure",
    textp:
      "Zero Agent Infrastructure Needed | Enterprise - Grade Features | Comprehensive Remote Monitoring. Currently struggling to take your contact center Remote? No Laptop, No WI-FI connectivity Contact us today and set up a Remote Contact Center in 3 days.",
  },
];
