import axios from "axios";
import {
  REQUEST_QUOTE_REQUEST,
  REQUEST_QUOTE_SUCCESS,
  REQUEST_QUOTE_FAIL,
} from "../constants/requestQuoteConstants";
import { hrmsBaseUrl } from "../shared/baseUrl";

export const createQuoteRequest =
  (companyName, email, service, description) => async (dispatch) => {
    try {
      dispatch({
        type: REQUEST_QUOTE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(
        hrmsBaseUrl + "/api/v1/requests",
        {
          companyName,
          email,
          service,
          description,
        },
        config
      );

      dispatch({
        type: REQUEST_QUOTE_SUCCESS,
        success: true,
      });
    } catch (error) {
      dispatch({
        type: REQUEST_QUOTE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
