import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { blogKey } from "../shared/baseUrl";
import Ai from "../assets/img/Ai.jpg";
import Ai2 from "../assets/img/Banking.jpg";
import Ai3 from "../assets/img/OutSourcing.jpeg";
import moment from "moment";

const Blogs = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [posts, setPosts] = useState([]);
  
  const images = [Ai, Ai2, Ai3]; // Predefined images

  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    centerMode: true,
    beforeChange: (_, next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
    ],
    className: "slick-track",
  };

  useEffect(() => {
    fetch(
      `https://www.googleapis.com/blogger/v3/blogs/2425642010638613354/posts?key=${blogKey}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.items) {
          const updatedPosts = data.items.map((post, index) => ({
            ...post,
            image: post.images ? post.images[0].url : images[index % images.length], // Assign image if available, otherwise cycle predefined images
          }));
          setPosts(updatedPosts);
        } else {
          console.error("No posts found");
        }
      })
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);

  return (
    <div className="game-section">
      <Slider {...settings} className="owl-carousel custom-carousel owl-theme">
        {posts.map((game, index) => (
          <div key={index} className="item active">
            <div
              className="game-card"
              style={{
                backgroundImage: `url(${game.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                // height: "400px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "flex-end",
                padding: "20px",
                color: "#fff",
              }}
            >
              <div
                className="item-desc"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <h5>{game.title}</h5>
                <a href={game.url} target="_blank" rel="noopener noreferrer">
                  <p style={{ color: "#f04217" }}>Read More</p>
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Blogs;


// <section className="game-section">
//   <h2 className="line-title">Trending Games</h2>
//   <div className="owl-carousel custom-carousel owl-theme">
//     <div
//       className="item active"
//       style={{
//         backgroundImage:
//           "url(https://www.yudiz.com/codepen/expandable-animated-card-slider/dota-2.jpg)",
//       }}>
//       <div className="item-desc">
//         <h3>Dota 2</h3>
//         <p>
//           Dota 2 is a multiplayer online battle arena by Valve. The game is
//           a sequel to Defense of the Ancients, which was a community-created
//           mod for Blizzard Entertainment's Warcraft III.
//         </p>
//       </div>
//     </div>

//     <div
//       className="item"
//       style={{
//         backgroundImage:
//           "url(https://www.yudiz.com/codepen/expandable-animated-card-slider/winter-3.jpg)",
//       }}>
//       <div className="item-desc">
//         <h3>The Witcher 3</h3>
//         <p>
//           The Witcher 3: Wild Hunt is an action role-playing game developed
//           by CD Projekt Red, based on The Witcher series of fantasy novels
//           by Andrzej Sapkowski.
//         </p>
//       </div>
//     </div>

//     <div
//       className="item"
//       style={{
//         backgroundImage:
//           "url(https://www.yudiz.com/codepen/expandable-animated-card-slider/rdr-2.jpg)",
//       }}>
//       <div className="item-desc">
//         <h3>Red Dead Redemption 2</h3>
//         <p>
//           Red Dead Redemption 2 is an action-adventure game developed and
//           published by Rockstar Games. It is a prequel to the 2010 game Red
//           Dead Redemption.
//         </p>
//       </div>
//     </div>

//     <div
//       className="item"
//       style={{
//         backgroundImage:
//           "url(https://www.yudiz.com/codepen/expandable-animated-card-slider/pubg.jpg)",
//       }}>
//       <div className="item-desc">
//         <h3>PUBG Mobile</h3>
//         <p>
//           PUBG Mobile is a battle royale game developed by PUBG Corporation,
//           where players fight to be the last one standing.
//         </p>
//       </div>
//     </div>

//     <div
//       className="item"
//       style={{
//         backgroundImage:
//           "url(https://www.yudiz.com/codepen/expandable-animated-card-slider/fortnite.jpg)",
//       }}>
//       <div className="item-desc">
//         <h3>Fortnite</h3>
//         <p>
//           Fortnite is a battle royale game developed by Epic Games,
//           featuring fast-paced building mechanics and multiplayer action.
//         </p>
//       </div>
//     </div>

//     <div
//       className="item"
//       style={{
//         backgroundImage:
//           "url(https://www.yudiz.com/codepen/expandable-animated-card-slider/far-cry-5.jpg)",
//       }}>
//       <div className="item-desc">
//         <h3>Far Cry 5</h3>
//         <p>
//           Far Cry 5 is a 2018 first-person shooter game developed by
//           Ubisoft, set in an open-world environment inspired by rural
//           America.
//         </p>
//       </div>
//     </div>
//   </div>
// </section>
