import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFunnelDollar,
  faPeopleArrows,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import RFQModal from "../components/RFQModal";
import Analytics from "../components/Analytics";
import Testimonials from "../components/Testimonials";
import CountUp from "react-countup";

const About = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const currentYear = new Date().getFullYear();
  const newYear = currentYear - 2006;

  return (
    <div id="about">
      <Navbar />
      <div className="about-intro">
        <div className="about-intro-container">
          <div className="about-intro-content">
            <h4>
              <span>Outcess</span> is an integrated customer engagement and
              business process outsourcing company. We deliver innovative
              digital solutions that empower and optimize the performance of
              people, teams and organizations leveraging technology.
            </h4>
            <button className="ccsnl-btn" onClick={handleShow}>
              Book a Demo
            </button>
          </div>
        </div>
      </div>
      <RFQModal show={show} setShow={setShow} />
      <main>
        <section className="about-tcess">
          <div className="about-tcss-dtls" data-aos="fade-right">
            <h3>
              Do <span>More</span> faster, with <span>Outcess</span>
            </h3>
            <p>
              Our Customers believe that digital technologies are driving a
              paradigm shift in how we live, work and play. This shift makes it
              possible and imperative for businesses to radically reshape how
              they interact and transact with customers and partners, seamlessly
              connecting and enhancing all aspects of their operations.
              Organisations that successfully embrace this shift will reap the
              benefits of reinventing their businesses, dramatically altering
              the ways in which they serve their markets, and in the process
              secure a significant digital advantage.
            </p>
          </div>
          <div className="tcss-yrs-active" data-aos="fade-left">
            <div>
              <p>
                MORE <br />
                THAN
              </p>
              <h1>
                {" "}
                <CountUp end={newYear} duration={5} separator="," />
              </h1>
              <p>
                YEARS OF <br /> EXPERIENCE <br />
                IN…
              </p>
            </div>
            <p>
              Customer <span>Engagement</span>,<br /> Business{" "}
              <span>Process Outsourcing</span>, <br /> Business Productivity and{" "}
              <span>Cloud solutions</span>.
            </p>
          </div>
        </section>
        <section className="about-cards-cntnr">
          <div
            className="about-card"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="100">
            <span>
              <FontAwesomeIcon icon={faFunnelDollar} size="3x" />
            </span>
            <h4>What we can do?</h4>
            <p>
              We put a strong focus on the needs of your business to figure out
              solutions that best fits your demand and nail it.
            </p>
          </div>

          <div
            className="about-card"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="400">
            <span>
              <FontAwesomeIcon icon={faPeopleArrows} size="3x" />
            </span>
            <h4>Become our partner?</h4>
            <p>
              Our Preventive and Progressive approach will help you take the
              lead while addressing possible threats in your business.
            </p>
          </div>

          <div
            className="about-card"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="700">
            <span>
              <FontAwesomeIcon icon={faHeadset} size="3x" />
            </span>
            <h4>Need a hand?</h4>
            <p>
              Our support team is available 24/7 a day, 7 days a week and can
              get ready for solving any of your situational rising problems.
            </p>
          </div>
        </section>
        <section className="ptml-sltn">
          <h3 data-aos="fade-up">
            We excel in delivering <span>optimal solutions</span>
          </h3>
          <ul>
            <li data-aos="fade-up" data-aos-delay="100">
              <h4>
                At <span>Outcess</span>
              </h4>
              <p>
                We reckon that organisations of all types and sizes face a dual
                imperative for success: run better for efficiency and run
                different for growth. Our innovative and tailor-made approach
                addresses this twin imperative and delivers to our clients the
                best technology and business expertise in today’s digital world.
              </p>
            </li>
            <li data-aos="fade-up" data-aos-delay="600">
              <h4>
                Point of <span>Difference</span>
              </h4>
              <p>
                Our uniqueness is driven by our culture. A culture defined by
                passionate and excellence driven people who go the extra mile to
                solve problems and create value. This culture is undergirded by
                5 imperatives namely: Professionalism, Innovation, Operational
                Excellence, Exceptional Customer Service, and Adaptability.
              </p>
            </li>
          </ul>
        </section>
      </main>

      <Analytics />

      <main>
        <section
          className="testimonials"
          data-aos="fade-up"
          data-aos-delay="300">
          <h3>
            {" "}
            What Our Clients Say{" "}
            <span style={{ color: "#e2522e" }}>About Us</span>{" "}
          </h3>
          <Testimonials />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default About;
