import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RFQModal from "../components/RFQModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";

const CustomerEngmntSolution = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <div id="customer-engmnt-solution">
      <Navbar />
      <div className="ces-intro">
        <div className="ces-intr-containr">
          <div className="ces-intr-contnt">
            <h4>
              {" "}
              Outcess Customer Engagement Services offer an integrated set of
              digital solutions to help organisations create engaging{" "}
              <span> customer experiences</span> and achieve{" "}
              <span> superior customer retention</span> and <span> growth</span>{" "}
              results.
            </h4>
            <button className="ccsnl-btn" onClick={handleShow}>
              Book a Demo
            </button>
          </div>
        </div>
      </div>
      <RFQModal show={show} setShow={setShow} />
      <main>
        <div className="ces-main-brief" data-aos="fade-in" data-aos-delay="100">
          <h4>
            As a leading provider of end-to-end customer engagement services in
            Nigeria and West Africa, we have exceptional expertise in the
            delivery of contact center and social engagement, and customer
            relationship management solutions.
          </h4>
        </div>
        <section className="ces-biz-prcss">
          <div className="ces-biz-prcss-info">
            <h5>Flexible and Multi-Lingual Contact Center</h5>
            <p>
              Our strategic partnerships with global leaders such as Microsoft
              and Ameyo, provides us with a robust portfolio of world-class
              offerings, from which to choose the solution that is right for
              you. With every engagement, our over-arching objective is to drive
              efficiency and performance at three levels of the customer
              engagement value chain.
            </p>
          </div>
          <div
            className="ces-biz-prcss-img"
            data-aos="fade-left"
            data-aos-delay="100"
          />
        </section>

        <section className="customer-cards-three">
          <div
            className="customer-card"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="100">
            <span>
              <FontAwesomeIcon icon={faPeopleArrows} size="3x" />
            </span>
            <h4>Acquire..</h4>
            <p>
              Our customer engagement solutions help you more accurately segment
              and target potential customers with the right products and
              messages, thereby improving lead generation, conversion and
              on-boarding efforts.
            </p>
          </div>
          <div
            className="customer-card"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="400">
            <span>
              <FontAwesomeIcon icon={faTachometerAlt} size="3x" />
            </span>
            <h4>Retain..</h4>
            <p>
              Through responsive engagement, exceptional service and speedy
              issue resolution, we ensure that we convert casual buyers into
              loyal customers. Our solution is also able to carefully analyse
              churn dynamics which gives us the insights to take proactive
              actions in order to mitigate customer attrition risks.
            </p>
          </div>
          <div
            className="customer-card"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="700">
            <span>
              <FontAwesomeIcon icon={faChartLine} size="3x" />
            </span>
            <h4> Grow...</h4>
            <p>
              Through the insights generated from engaging customers, we are
              able to uncover new opportunities to maximize customer value by
              inducing frequency of use, cross-selling and up-selling, thereby
              growing your share of their wallet.
            </p>
          </div>
        </section>

        <section className="ces-others-cards">
          <div className="ces-othrs-card">
            <div
              className="ces-othrs-card-img"
              data-aos="fade-in"
              data-aos-delay="100"
            />
            <div className="ces-othrs-card-info" data-aos="fade-right">
              <p>Transform every touchpoint into a marketing opportunity</p>
              <h5>With marketing automation...</h5>
              <p>
                Organisations can engage customers across channels. You can plan
                execute and measure multichannel marketing campaigns from start
                to finish. You can manage nurture and score leads to generate
                more revenue, while streamlining marketing planning and
                execution and gaining a true view of marketing ROI. Our
                marketing automation capabilities include: Lead Management, CRM
                Integration, Social Marketing, Marketing Analytics, Campaign
                Management and Analysis, Marketing Resource Management
              </p>
              <button className="ccsnl-btn" onClick={handleShow}>
                Book a Demo
              </button>
            </div>
          </div>
          <div className="ces-othrs-card">
            <div
              className="ces-othrs-card-img2"
              data-aos="fade-in"
              data-aos-delay="100"
            />
            <div className="ces-othrs-card-info" data-aos="fade-left">
              <p>Customer Relationship Management (CRM)</p>
              <h5>CRM foundation</h5>
              <p>
                Building a solid CRM foundation is critical to customer
                engagement success. Outcess not only helps you identify the best
                CRM technologies for your business, we also help you employ
                effective change management strategies to align your people and
                processes with customer relationship goals. Our CRM practice
                possesses significant experience implementing CRM solutions in a
                variety of industries. We have been involved in Customer
                Relationship Management implementations for over 9 years and are
                experts in deploying Microsoft Dynamics 365 from development,
                implementation and maintenance to testing, upgrades and managed
                services.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default CustomerEngmntSolution;
