import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RequestQuote from "../components/RequestQuote";
import Quoteimg from "../assets/img/qouteelementor.png";

const TransformCSForScalableCloudCaseStudy = () => {
  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <div className="case_title_container9">
        <main>
          <div className="case_title">
            <h1>
            Transforming Customer Support for a Scalable Cloud and Contact Center
            </h1>
          </div>
        </main>
      </div>
      {/* Background Section */}

      {/* Background Section */}
      <section className="multiChoice-background-section">
        <main>
          <h3 className="multiChoice-subsection-title">Background</h3>
          <p className="multiChoice-text">
           In the fast-evolving financial services sector, where expanding user bases and diverse product offerings are the norm, exceptional customer support is non-negotiable. Our customer, a premier securities depository and settlement company, faced challenges in managing an increasing volume of customer inquiries across multiple communication channels, including email, chat, tickets, and phone calls.
          </p>
        </main>
      </section>

      {/* The Challenge Section */}
      <section className="multiChoice-challenge-section">
        <main>
          <h2 className="multiChoice-section-title">The Challenge</h2>
          <p className="multiChoice-text">
           As our customer expanded its operations and services, it required a solution that could:
          </p>
          <ul className="multiChoice-challenges-list">
            <li>
              <span className="multiChoice-challenge-text">
               Seamlessly scale with fluctuating demand, especially during product rollouts and increased stakeholder engagement.
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-text">
                Meet strict performance goals, such as 3-minute response times and 80% customer satisfaction rates.
              </span>
            </li>
            <li>
              <span className="multiChoice-challenge-text">
               Maintain high-quality service while managing the daily influx of enquiries.
              </span>
            </li>
          </ul>
        </main>
      </section>

      {/* Our Approach Section */}
      <section className="multiChoice-approach-section">
        <main>
          <h2 className="multiChoice-section-title">Our Approach</h2>
          <p className="multiChoice-text">
          Leveraging on our strategic partner network, we implemented a tailored contact center and cloud IT support solution to address our customer unique needs. Our approach integrated advanced cloud-based technology with expert customer service practices.
          </p>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Multichannel Support Teams:</p>
            <p className="multiChoice-outcome-text">
              Highly trained agents were deployed to manage inquiries across email, chat, social media, and voice channels, ensuring consistent service quality.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Scalable Operations:</p>
            <p className="multiChoice-outcome-text">
              Scalable Operations: Leveraging on our partner’s cloud infrastructure, we created a flexible staffing model that allowed our customer to handle peak demand without service disruptions.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Data-Driven Optimization:</p>
            <p className="multiChoice-outcome-text">
              By implementing advanced analytics and reporting tools, we tracked recovery performance and segmented customers based on repayment likelihood. These insights allowed for targeted engagement, increasing recovery efficiency.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Compliance and Professionalism:</p>
            <p className="multiChoice-outcome-text">
             Utilizing advanced analytics tools, we provided actionable insights to streamline operations, improve response times, and enhance customer experiences.
            </p>
          </div>
        </main>
      </section>

      {/* Outcomes Section */}
      <section className="multiChoice-outcomes-section">
        <main>
          <h2 className="multiChoice-section-title">Key Results</h2>
          <h3>Our solution delivered measurable results::</h3>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Efficiency Gains:</p>
            <p className="multiChoice-outcome-text">
              A significant reduction in first response times.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Scalability Achieved:</p>
            <p className="multiChoice-outcome-text">
              Successfully managed a 20% increase in inquiry volumes during peak periods without compromising performance.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Customer Satisfaction:</p>
            <p className="multiChoice-outcome-text">
              Consistently met performance targets, enhancing service delivery and stakeholder engagement.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Cost Optimization</p>
            <p className="multiChoice-outcome-text">
             Streamlined operations across all channels, reducing overall support costs.
            </p>
          </div>
          <div className="multiChoice-outcome-item">
            <p className="multiChoice-outcome-title">Predictive Insights:</p>
            <p className="multiChoice-outcome-text">
             Enabled accurate capacity planning and campaign management through analytics-driven forecasting.
            </p>
          </div>
        </main> 
      </section>
      <section>
        <main>
          <div className="request_quote_container">
            <img src={Quoteimg} alt="Quote img" className="Quoteimg" />

            <div>
              <RequestQuote />
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default TransformCSForScalableCloudCaseStudy;
